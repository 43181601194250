import {
    Box, Button, Image,
    ResponsiveContext, Text, TextInput
} from "grommet";
import { Hide, View } from "grommet-icons";
import React, { useState } from "react";
import Layout from '../components/elements/layout';
import mainLogo from "../images/logo.png";
import service from '../services/login_service';
import storageService from '../services/storage_service';

const initDev = () => {
    if(typeof window !== 'undefined') {
        if(window.location.href.includes('localhost:8002')) {
            return ({
              username: 'commerciale@trustiway.com',
              password: '123456'  
            })
        }
    }
    return {username: '', password: ''}
}

const LoginPage = ({ size, logo }) => {

    const [username, setUserName] = useState(initDev().username);
    const [password, setPassword] = useState(initDev().password);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [reveal, setReveal] = useState(false);

    const login = () => {
        if (!username || !username.includes('@') || !username.includes('.') || username.length < 6) {
            setError('Identifiant non valid'); return;
        }

        if (!password || password.length < 6) {
            setError('Mot de passe au moin 6 caractères'); return;
        }
        setError('');
        setLoading(true);
        service.login(username, password)
            .then(res => {
                setLoading(false);
                storageService.saveAuth(res.data);
                window.location.href = `/home`;
            }).catch(err => {
                setLoading(false);
                setError('Mauvais email/mot de pass');
            });
    }

    const handleEnter = event => {
        if (event.keyCode === 13) login();
    }

    const inputStyle = { background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none' };
    return (
        <Layout style={{ justifyContent: "center", alignContent: "center" }}
        >
            <ResponsiveContext.Consumer>
                {size =>
                    <Box
                        justify="center"
                        as="main"
                        flex
                        overflow="auto"
                        width="large"
                        direction="column"
                        alignContent="center"
                        alignSelf="center"
                    >
                        <Box width="medium" alignSelf="center" justify="center" alignContent="center" gap="medium">
                            <Image src={logo || mainLogo} />

                            <Text size="small" style={{ color: '#dc3545' }} textAlign="center">{error}</Text>
                            <Box width="large" gap="small">
                                <Box direction="row" align="center" background={'#EDEDED'} round={{ size: 'small' }} pad="xxsmall">
                                    <TextInput
                                        placeholder="Email "
                                        value={username}
                                        onChange={event => setUserName(event.target.value)}
                                        style={inputStyle}
                                    />
                                </Box>
                                <Box direction="row" align="center" background={'#EDEDED'} round={{ size: 'small' }}>
                                    <TextInput
                                        placeholder="Mot de passe "
                                        type={reveal ? 'text' : "password"}
                                        onKeyDown={handleEnter}
                                        onChange={event => setPassword(event.target.value)}
                                        style={inputStyle}
                                        value={password}
                                    />

                                    <Button
                                        size="small"
                                        icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                        onClick={() => setReveal(!reveal)}
                                    />
                                </Box>
                                <Box width="small" alignSelf="center">
                                    <Button primary label="Connexion" onClick={login} disabled={loading} />
                                </Box>
                                <Box width="large" alignSelf="center">
                                    <Text style={{ color: '#707070', fontSize: 14 }} textAlign="center">J'ai oublié mon mot de passe</Text>
                                </Box>
                            </Box>
                        </Box>

                    </Box>

                }
            </ResponsiveContext.Consumer>

        </Layout>
    );
}

export default LoginPage;